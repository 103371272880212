import { faTwitch, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserView, MobileView } from "react-device-detect";
import "../css/Home.css";

const Text = {
    Description: "Twitch streamer, Bedwars player, Father of Wendy"
}

const ActiveStyling = {
    color: "whitesmoke",
    marginLeft: "0.5vw"
}

const MobileActiveStyling = {
    color: "whitesmoke",
    marginLeft: "3vw"
}

export default function Home() {
    return (
        <div className="Core-Page Util-TextCenter Util-DivFlexCenter Util-FlexHeight90 Util-FlexWrap">
            <div>
                <BrowserView>
                    <div className="Util-DivFlexCenter Util-FlexHeight90 Util-FlexWrap">
                        <img alt="wnedy!" src="/wendy.png" width="100vw" style={{marginRight: "2.5vw"}} />
                        <h1 className="Home-Greeting">Shooken</h1>
                    </div>
                </BrowserView>
                <MobileView>
                <div className="Util-DivFlexCenter Util-FlexHeight90 Util-FlexWrap">
                        <div className="Mobile-SecBreak">
                            <img alt="wnedy!" src="/wendy.png" width="100vw" />
                            <h1 className="Home-Greeting">Shooken</h1>
                        </div>
                    </div>
                </MobileView>
            </div>
            <div className="Home-Description">
                <MobileView>  
                    <h1 style={{ fontSize: "2.5vh" }}>{Text.Description}</h1>
                </MobileView>
                <BrowserView>
                    <h1>{Text.Description}</h1>
                </BrowserView>
                <div style={{ marginTop: "3vh" }}>
                    <MobileView>
                        <a href="https://www.youtube.com/channel/UC1tGY6qdS2aQsHgCyCA5Qkw" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faYoutube} size="3x" style={MobileActiveStyling} />
                        </a>
                        <a href="https://twitter.com/shookenlive" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faTwitter} size="3x" style={MobileActiveStyling} />
                        </a>
                        <a href="https://twitch.tv/shookenlive" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faTwitch} size="3x" style={MobileActiveStyling} />
                        </a>
                        <a href="https://ko-fi.com/shooken" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faCoffee} size="3x" style={MobileActiveStyling} />
                        </a>
                    </MobileView>
                    <BrowserView>
                        <a href="https://www.youtube.com/channel/UC1tGY6qdS2aQsHgCyCA5Qkw" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faYoutube} size="3x" style={ActiveStyling} />
                        </a>
                        <a href="https://twitter.com/shooken64" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faTwitter} size="3x" style={ActiveStyling} />
                        </a>
                        <a href="https://twitch.tv/shookenlive" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faTwitch} size="3x" style={ActiveStyling} />
                        </a>
                        <a href="https://ko-fi.com/shooken" target="_blank" rel="noreferrer noopener" style={ActiveStyling}>
                            <FontAwesomeIcon icon={faCoffee} size="3x" style={ActiveStyling} />
                        </a>
                    </BrowserView>
                </div>
            </div>
        </div>
    );
};
