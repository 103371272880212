import "./css/Core.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Frame from "./Frame";
import Home from "./router/Home.jsx";
import Stream from "./router/Stream.jsx";
import NotFound from "./router/404.jsx";

function App() {
  return (
    <div className="Core">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Frame />}>
            <Route index element={<Home />} />
            <Route path="/stream" element={<Stream />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
