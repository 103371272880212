import { Outlet } from 'react-router-dom';
import NavLink from "./componentry/NavReplacement.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrog } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect';

const NavSelected = { color: "#6c7180" };

export default function Frame() {
    return (
        <div>
            <nav className="Navigation-Bar">
                
                <ul className="Navigation-List">
                    <NavLink to="/">
                        <MobileView>
                            <FontAwesomeIcon icon={faFrog} style={{color: "#1C1E23", marginLeft: "-10vw"}} size="2x" />
                        </MobileView>
                        <BrowserView>
                            <FontAwesomeIcon icon={faFrog} style={{color: "#1C1E23"}} size="2x" />
                        </BrowserView>
                    </NavLink>
                    <li>
                        <NavLink to="/" className="Navigation-Link" activeStyle={NavSelected}>Home</NavLink>
                    </li>
                    <li className="Navigation-Tab">
                        <a href="https://ko-fi.com/shooken" target="_blank" rel="noreferrer noopener" className="Navigation-Link">Donate</a>
                    </li>
                    <li className="Navigation-Tab">
                        <NavLink to="/stream" className="Navigation-Link" activeStyle={NavSelected}>Stream</NavLink>
                    </li>
                </ul>
            </nav>

            <Outlet />
        </div>
    );
};