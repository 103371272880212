import { BrowserView, MobileView } from "react-device-detect";
import "../css/Stream.css";

export default function Stream() {
    return (
        <div>
            <MobileView>
                <div className="Core-Page">
                    <h1 className="StreamMobile-Header">Sorry.</h1>
                    <h2 className="StreamMobile-Subheader">Stream Embed isn't available for mobile at the moment. Apologies for the inconveniences that this may have caused.<br /><br />In the mean time, why not try checking out <a rel="noreferrer noopener" target="_blank" href="https://twitch.tv/shookenlive" style={{ color: "#8a94a6" }}>Shooken's stream on the Twitch app</a>?</h2>
                </div>
            </MobileView> 
            <BrowserView style={{ backgroundColor: "#18181b" }}>
                <iframe title="chat" src="https://www.twitch.tv/embed/shookenlive/chat?parent=shooken.live&parent=www.shooken.live&parent=shookenlive.vercel.app&darkpopout" style={{ backgroundColor: "black", width: "20vw", height: "90vh", borderStyle: "none" }} allowFullScreen={false} />
                <iframe title="stream" src="https://player.twitch.tv/?channel=shookenlive&parent=shooken.live&parent=www.shooken.live&parent=shookenlive.vercel.app" style={{ backgroundColor: "black", marginLeft: "2.5vw", width: "75vw", height: "75vh", position: "absolute", borderStyle: "none" }} allowFullScreen={true} />
            </BrowserView>
        </div>
    );
};